import React from 'react'
import { CgScrollV } from "react-icons/cg";


function Hero() {

  const handleButtonClick = () => {
    // Scroll to a specific position on the page
    window.scroll({
      top: 1000, // Adjust this value to the desired scroll position
      behavior: 'smooth', // Use smooth scrolling
    });
  };



  return (
<div className='hero-section relative h-screen'>

<div className='absolute inset-x-0 bottom-0 px-4 md:px-8 lg:px-16 xl:px-32 flex flex-col justify-center items-center pb-3 md:pb-12 lg:pb-3'>
  <div className='flex flex-col justify-center items-center'>
    <div className='fade-in'>
      <h1 className='text-center text-white font-bold text-4xl md:text-7xl'>RescuEnergy</h1>
      <p className='text-white mt-4 md:mt-6 lg:mt-10 text-center text-xl md:text-2xl'>Bureau d'études expert en GEOTHERMIE.</p>
      <div className='flex justify-center mt-5'>
        <button
          className='text-white font-medium flex items-center px-6 rounded-xl hover:bg-transparent mt-4 md:mt-8 lg:mt-15 automatic-bounce'
          onClick={handleButtonClick}
        >
          <CgScrollV className='w-12 h-12 md:w-16 md:h-16' />
        </button>
      </div>
    </div>
  </div>
</div>

</div>

  )
}

export default Hero
