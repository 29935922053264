import React from 'react'
import './App.css'
import Hero from './Components/Hero'
import About from './Components/About'

function App() {
  return (
   <>
   <Hero/>
   <About/>
   
   </>
  )
}

export default App
