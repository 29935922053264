import React, { useState, useEffect } from 'react';
import { HiOutlineMail } from "react-icons/hi";


const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            setIsVisible(scrollTop > 200); // Show button when scrolling down 200px
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <button
            className={`fixed right-4 automatic-bounce bottom-4 bg-slate-900 text-white px-2 py-2 rounded transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'
                }`}

        >

            <a
                href="mailto:contact@rescuenergy.com"
                className="block py-2 px-3 text-white"
            >
                <HiOutlineMail  className='w-6 h-6 ' />
            </a>

        </button>
    );
};

export default ScrollToTopButton;
