import React, { useEffect, useRef } from 'react'
import img1 from '../assets/images/benefit1.jpg'
import img2 from '../assets/images/benefit2.jpg'
import img3 from '../assets/images/benefit3.jpg'
import img4 from '../assets/images/benefit4.jpg'
import img5 from '../assets/images/benefit5.jpg'
import img6 from '../assets/images/benefit6.jpg'
import img7 from '../assets/images/benefit7.jpg'
import img8 from '../assets/images/benefit8.jpg'
import img9 from '../assets/images/benefit9.jpg'
import img10 from '../assets/images/benefit10.jpg'
import img11 from '../assets/images/benefit11.jpg'
import img12 from '../assets/images/benefit12.jpg'
import img13 from '../assets/images/benefit13.jpg'
import team from '../assets/images/geothermie.png'
import bulb from '../assets/images/bulb.png'
import hat from '../assets/images/hat.png'
import c1 from '../assets/images/c1.png'
import c2 from '../assets/images/c2.png'
import c3 from '../assets/images/c3.png'
import c4 from '../assets/images/c4.png'
import c5 from '../assets/images/c5.png'
import c6 from '../assets/images/c6.png'
import posibality from '../assets/images/posibility-img.png'
import Navbar from './Navbar'
import Counter from './Counter'
import ScrollToTopButton from './ScrollUpButton'
import { GoDotFill } from "react-icons/go";
import child from '../assets/images/child.png'
import { HiOutlineMail } from "react-icons/hi";
import map from '../assets/images/map.svg'

function About() {

    const sectionRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.fade-in');

            elements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                const elementBottom = element.getBoundingClientRect().bottom;

                // Check if the element is in the viewport
                if (elementTop < window.innerHeight && elementBottom >= 0) {
                    element.classList.add('fade-in-visible');
                } else {
                    element.classList.remove('fade-in-visible');
                }
            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Trigger the initial check on mount
        handleScroll();

        // Remove the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const imageInfo = [
        { url: img1, label: 'Eco quartiers' },
        { url: img2, label: 'Hypermarchés' },
        { url: img3, label: 'Serres' },
        { url: img4, label: 'Hôpitaux' },
        { url: img5, label: 'Data centers' },
        { url: img6, label: 'Piscines' },
        { url: img7, label: 'Institutions        ' },
        { url: img8, label: 'Écoles' },
        { url: img9, label: 'Maisons de retraite' },
        { url: img10, label: 'Usines' },
        { url: img11, label: 'Tourisme' },
        { url: img12, label: 'Tertiaire' },
        { url: img13, label: 'Élevage' },
    ];

    const imageInfo2 = [
        { url: c1 },
        { url: c2 },
        { url: c3 },
        { url: c4 },
        { url: c5 },
        { url: c6 },

    ];





    return (
        <div className=''>
            <Navbar />
            <ScrollToTopButton />
            <div className=' px-6 mt-20  '>
                <div className='grid grid-cols-12 gap-5'>
                    <Counter endValue={-60} duration={2000} delay={0.5} title='cout annuel' isPercentage />
                    <Counter endValue={-80} duration={2000} delay={0.5} title='Consommation énergétique' isPercentage />
                    <Counter endValue={-90} duration={2000} delay={0.5} title='Emissions CO2' isPercentage />
                    <Counter endValue={1500000 } duration={2000} delay={0.5} title='CA' isPercentage={false} />
                </div>
                <div className='mt-28 px-6 lg-px-32 border-b border-black pb-4' ref={sectionRef}>
                    <div className='grid grid-cols-12 gap-4 justify-center'>
                        <div className='col-span-12 md:col-span-12 lg:col-span-12 flex justify-center fade-in '>
                            <img className=' fade-in w-24' src={team} alt="" />
                        </div>
                        <div className='col-span-12 md:col-span-12 lg:col-span-12 fade-in flex justify-center text-center'>
                            <div>
                                <h1 className='text-2xl font-bold px-2 '>
                                    Expert en GEOTHERMIE, les prestations proposés sont les suivantes
                                </h1>
                                <div className='flex justify-center'>
                                    <div className='mt-5'>
                                        <p className='font-medium text-lg text-gray-500 flex text-center py-1'><GoDotFill className='mt-1' />  Modelisation du sous sols</p>
                                        <p className='font-medium text-lg text-gray-500 flex py-1'> <GoDotFill className='mt-1' /> Dimensionnement</p>
                                        <p className='font-medium text-lg text-gray-500 flex py-1'><GoDotFill className='mt-1' />  Calcul des investissements</p>
                                        <p className='font-medium text-lg text-gray-500 flex py-1'><GoDotFill className='mt-1' />  Bilan carbone</p>
                                        <p className='font-medium text-lg text-gray-500 flex py-1'><GoDotFill className='mt-1' />  Dossier de consultations</p>
                                        <p className='font-medium text-lg text-gray-500 flex py-1'><GoDotFill className='mt-1' />  Suivi des travaux et reception</p>
                                        <p className='font-medium text-lg text-gray-500 flex py-1'><GoDotFill className='mt-1' />  Maintenance des puits</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className='mt-28 px-6 lg-px-32'>
                    <div className='grid grid-cols-12 gap-4 justify-center border-b pb-5 border-black'>
                        <div className='col-span-12 md:col-span-12 lg:col-span-12 flex justify-center px-0 lg:px-44'>
                            <div className='text-center flex justify-center'>
                                <div className='flex flex-col items-center'>
                                    <img className='w-24' src={bulb} alt="" />
                                    <h1 className='text-2xl font-bold px-2'>
                                        Pourquoi faire appel à RescuEnergy?
                                    </h1>

                                    <p className='text-gray-400 text-lg mt-10 '>Nous vous accompagnons <span className='font-bold text-black'>tout au long de votre projet</span> : de l’étude de faisabilité à la maintenance, sans oublier l’aide pour les <span className='font-bold text-black'>demandes de financements*</span>.</p>
                                    <p className='text-gray-400 text-lg mt-5 '><span className='text-black'>RescuEnergy</span> intervient sur votre projet en mettant à votre disposition l' <span className='text-black'>expertise de ses ingénieurs</span> en <span className='text-black'>hydrogéologie, forage, modélisation numérique, thermique</span> du bâtiment et gestion de projet, pour concevoir et mettre en œuvre des systèmes géothermiques très basses énergie dits "de surface" <span className='text-black'>conçus sur mesure</span>.</p>
                                    <p className='text-gray-400 text-lg mt-5 '>* Grâce à notre qualification RGE, Vous pouvez bénéficier d’ <span className='text-black'>aides publiques</span>, à hauteur de 50% sur les coûts de <span className='text-black'>faisabilité</span> et <span className='text-black'>25% </span>sur les coûts d’<span className='text-black'>investissement</span>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='mt-28 px-6 lg-px-32 border-b border-black pb-4' ref={sectionRef}>
                    <div className='grid grid-cols-12 gap-4 justify-center'>
                        <div className='col-span-12 md:col-span-12 lg:col-span-12 flex justify-center fade-in '>
                            <img className='fade-in w-28' src={child} alt="" />
                        </div>
                        <div className='col-span-12 md:col-span-12 lg:col-span-12 fade-in flex justify-center '>
                            <div>
                                <h1 className='text-2xl font-medium px-2 text-center '>
                                    Nos valeurs
                                </h1>
                                <div className='flex justify-center'>
                                    <ul className='mt-5 '>
                                        <li className='font-medium text-lg text-gray-500  text-center py-1 flex'> <GoDotFill className='mt-1' /> Assurer la sécurite</li>
                                        <li className='font-medium text-lg text-gray-500  text-center py-1 flex'> <GoDotFill className='mt-1' />Satisfaction client </li>
                                        <li className='font-medium text-lg text-gray-500  py-1 flex'> <GoDotFill className='mt-1' /> Atteindre les objectifs fixés par le client </li>
                                        <li className='font-medium text-lg text-gray-500 text-center py-1 flex'> <GoDotFill className='mt-1' /> Être professionnel </li>
                                        <li className='font-medium text-lg text-gray-500 text-center py-1 flex'> <GoDotFill className='mt-1' /> Être réactif</li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-16 border-b pb-5 border-black '>
                    <h1 className='text-2xl font-bold px-2 text-center'>Qui peut profiter de la géothermie?</h1>
                    <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 gap-4 mt-12">
                        {imageInfo.map((info, index) => (
                            <div
                                key={index}
                                className="overflow-hidden flex flex-col items-center fade-in"
                                style={{ transitionDelay: `${index * 0.2}s` }}
                            >
                                <img
                                    src={info.url}
                                    alt={`Image ${index + 1}`}
                                    className="w-28 self-center"
                                />
                                <h1 className="text-center font-bold mt-2">{info.label}</h1>
                            </div>
                        ))}
                    </div>
                </div>



                <div className='mt-28 px-6 lg-px-32 ' >
                    <div className='grid grid-cols-12 gap-4 justify-center border-b pb-5 border-black'>
                        <div className='col-span-12 md:col-span-12 lg:col-span-12 flex justify-center px-0 lg:px-44 '>
                            <div className='text-center flex justify-center'>
                                <div className='flex flex-col items-center ' ref={sectionRef}>
                                    <img className='w-24' src={hat} alt="" />
                                    <h1 className='text-2xl md:text-5xl font-bold px-2 mt-2 fade-in'>
                                        Ils nous font confiance
                                    </h1>
                                    <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-6 gap-4 mt-12">
                                        {imageInfo2.map((info, index) => (
                                            <div
                                                key={index}
                                                className="overflow-hidden flex items-center  fade-in"
                                                style={{ transitionDelay: `${index * 0.2}s` }}
                                            >
                                                <img
                                                    src={info.url}
                                                    alt={`Image ${index + 1}`}
                                                    className="w-full self-center"
                                                />
                                                <h1 className="text-center font-bold mt-2">{info.label}</h1>
                                            </div>
                                        ))}
                                    </div>

                                    <button className='mt-5 px-8 py-4 text-xl rounded-lg bg-slate-950 text-white font-bold hover:bg-slate-800'>
                                        Comme eux, <br /> faites-nous confiance
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='mt-28 px-6 lg-px-32'>
                    <div className='grid grid-cols-12 gap-4 justify-center '>
                        <div className='col-span-12 md:col-span-12 lg:col-span-12 flex justify-center px-0 lg:px-44'>
                            <div className='text-center flex justify-center'>
                                <div className='flex flex-col items-center'>
                                    <img className='w-72' src={posibality} alt="" />
                                    <h1 className='text-2xl font-bold  px-2 mt-2'>
                                        Que pouvons-nous faire pour vous?
                                    </h1>

                                    <p className='font-medium text-xl  mt-4'>
                                        RescuEnergy vous accompagne durant toutes les étapes du projet.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-10 gap-5 mt-12 pt-10 px-2 pb-12 ' ref={sectionRef}>
                    <div className='col-span-12 md:col-span-2 lg:col-span-2 fade-in  '>
                        <div>
                            <h1 className='font-bold text-2xl  text-center  '><span className='bg-slate-950 rounded-full text-white px-4 py-2'>1</span></h1>
                            <p className='text-center text-lg  text-gray-400 mt-5'>L’étude thermique préliminaire avec note d’opportunité</p>
                        </div>
                    </div>
                    <div className='col-span-12 md:col-span-2 lg:col-span-2 fade-in  '>
                        <div>
                            <h1 className='font-bold text-2xl  text-center  '><span className='bg-slate-950 rounded-full text-white px-4 py-2'>2</span></h1>
                            <p className='text-center text-lg  text-gray-400 mt-5'>L’étude de préfaisabilité Si besoin, le financement et La demande de garantie Aquapac

                            </p>
                        </div>
                    </div>
                    <div className='col-span-12 md:col-span-2 lg:col-span-2 fade-in  '>
                        <div>
                            <h1 className='font-bold text-2xl  text-center  '><span className='bg-slate-950 rounded-full text-white px-4 py-2'>3</span></h1>
                            <p className='text-center text-lg  text-gray-400 mt-5'>La faisabilité comprenant les forages de test

                                4
                            </p>
                        </div>
                    </div>
                    <div className='col-span-12 md:col-span-2 lg:col-span-2 fade-in  '>
                        <div>
                            <h1 className='font-bold text-2xl  text-center  '><span className='bg-slate-950 rounded-full text-white px-4 py-2'>4</span></h1>
                            <p className='text-center text-lg  text-gray-400 mt-5'>Les travaux

                            </p>
                        </div>
                    </div>
                    <div className='col-span-12 md:col-span-2 lg:col-span-2  fade-in '>
                        <div>
                            <h1 className='font-bold text-2xl  text-center  '><span className='bg-slate-950 rounded-full text-white px-4 py-2'>5</span></h1>
                            <p className='text-center  text-lg text-gray-400 mt-5'>La maintenance

                            </p>
                        </div>
                    </div>




                </div>

            </div>

            <footer className="bg-gray-800 text-white p-4 text-center mt-5 pb-12">
                <div
                    className='font-medium mt-5'>
                    <p className='text-2xl'>RescuEnergy <br /></p>
                    <p className='text-lg'>459 chemin de la tete de l’ost</p>
                    <p className='text-lg'>13105 MIMET</p>
                    <p className='text-lg'>  SIRET 900398454 00026 RCS AIX EN PROVENCE <br /></p>
                    <p className='text-lg'>  TVA FR96900398454 <br /></p>
                    <p className='text-2xl'>Contactez-nous au 0767375656 ou 0695359739</p></div>
                <p className='mt-5'>
                    Nos experts sont là pour vous accompagner tout au long de votre projet. Pour plus de <br /> renseignements sur notre offre, n'hésitez pas à nous contacter.
                </p>
                <p className='mt-5 flex justify-center'>
                    <a href="mailto: contact@rescuenergy.coml"><HiOutlineMail className='w-12 h-12' /></a>

                </p>
                <div className='mt-5 flex justify-center'>
                  <img className='w-64' src={map} alt="" />
                    
                </div>
            </footer>
        </div>
    )
}

export default About
