import React, { useState, useEffect, useRef } from 'react';

const Counter = ({ endValue, duration, delay, title, isPercentage }) => {
    const [count, setCount] = useState(0);
    const counterRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    let startTimestamp;
                    let animationFrame;

                    const startAnimation = (timestamp) => {
                        if (!startTimestamp) startTimestamp = timestamp;
                        const progress = timestamp - startTimestamp;

                        if (isPercentage) {
                            setCount(Math.floor(Math.min(progress / duration, 1) * Math.abs(endValue)));
                        } else {
                            if (title === 'CA') {
                                const countIncrement = (Math.abs(endValue) / duration) * progress;
                                setCount(progress < duration ? Math.floor(countIncrement) : Math.abs(endValue));
                            } else {
                                setCount(
                                    Math.floor(progress / duration * (Math.abs(endValue) - count) + count)
                                );
                            }
                        }

                        if (progress < duration) {
                            animationFrame = requestAnimationFrame(startAnimation);
                        }
                    };

                    const handleDelay = () => {
                        setTimeout(() => {
                            startTimestamp = null;
                            animationFrame = requestAnimationFrame(startAnimation);
                        }, delay * 1000);
                    };

                    handleDelay();
                }
            },
            { threshold: 0.5 }
        );

        observer.observe(counterRef.current);

        return () => observer.disconnect();
    }, [endValue, duration, delay, isPercentage, title]);

    const showEuroSymbol = title === 'CA';

    return (
        <div ref={counterRef} className='col-span-12 md:col-span-3 lg:col-span-3'>
            <div className='fade-in'>
                <h1 className='font-bold text-2xl md:text-4xl text-center text-slate-900'>
                    {isPercentage ? `${count}%` : showEuroSymbol ? `${count} Euros` : count}
                </h1>
                <p className='text-center font-medium text-gray-400'>{title} </p>
            </div>
        </div>
    );
};

export default Counter;
